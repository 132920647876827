<template>
    <div class="px-4 px-lg-5 py-3 text-left">
        <h1>Contact Us</h1>
        <br>
        Discord Server: <a href="https://discord.gg/PJR2CEq" class="text-info" target="_blank">https://discord.gg/PJR2CEq</a>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Contact extends Vue {

    created(): void {
        this.$meta.use({
            title: 'Contact Us | StatHub.gg',
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: 'StatHub.gg - Contact Us.'
            }
        })
    }

}
</script>
